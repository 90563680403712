import React from "react";

import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import formatNumber from "../../utils/formatNumber";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const Chart = ({ data, options, label }) => {
  const getMin = (data) => formatNumber(Math.min(...data.datasets[0].data));
  const getMax = (data) => formatNumber(Math.max(...data.datasets[0].data));
  const getAvg = (data) => {
    const sum = data.datasets[0].data.reduce((acc, val) => acc + val, 0);
    if (sum === 0) return 0;
    return formatNumber(sum / data.datasets[0].data.length);
  };
  return (
    <>
      <div className="card z-index-2">
        <div className="card-header p-0 position-relative mt-n4 mx-3 z-index-2 bg-transparent">
          <div className="bg-gradient-success shadow-success border-radius-lg py-3 pe-1">
            <div className="chart">
              <Line
                data={data}
                options={options}
                height={170}
                className="chart-canvas"
              />
            </div>
          </div>
        </div>
        <div className="card-body">
          <h6 className="mb-0 fw-bold">{label}</h6>
          <p className="text-sm ">
            Média: <span className="font-weight-bolder">{getAvg(data)}</span>
            {"  |  "}
            Min: <span className="font-weight-bolder">{getMin(data)}</span>
            {"  |  "}
            Max: <span className="font-weight-bolder">{getMax(data)}</span>
          </p>
          {/* <hr className="dark horizontal"> */}
          {/* <div className="d-flex ">
            <i className="material-icons text-sm my-auto me-1">Atualizado</i>
            <p className="mb-0 text-sm"> 12:12 </p>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Chart;
