import React from "react";
import { useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
library.add(fas);

const Navbar = ({ currPathname }) => {
  const navigate = useNavigate();
  const handleRedirect = (pathname) => navigate({ pathname: `/${pathname}` });

  return (
    <nav
      className="navbar navbar-light bg-dark pb-0 w-100"
      style={{ position: "fixed", top: 0, zIndex: 100 }}
    >
      <div className="container d-flex m-0">
        <div className="col d-flex justify-content-start gap-2 m-0">
          <button
            className={`btn btn-outline-tertiary ${
              currPathname === "dashboard"
                ? "btn-nav-custom-active"
                : "btn-nav-custom"
            }`}
            onClick={(e) => handleRedirect("dashboard")}
          >
            <FontAwesomeIcon icon="fa-chart-line" className="ms-1 me-2" />
            DASHBOARD
          </button>

          <button
            className={`btn btn-outline-tertiary ${
              currPathname === "profile"
                ? "btn-nav-custom-active"
                : "btn-nav-custom"
            }`}
            onClick={(e) => handleRedirect("profile")}
          >
            <FontAwesomeIcon icon="fa-id-badge" className="ms-1 me-2" />
            PERFIL
          </button>
        </div>
      </div>
      {/* <button
        className="btn btn-outline-danger"
        style={{ display: "absolute", bottom: 3, right: 0 }}
        onClick={(e) => handleRedirect("")}
      >
        SAIR
      </button> */}
    </nav>
  );
};

export default Navbar;
