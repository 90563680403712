import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import Chart from "../../components/Chart";
import { useNavigate } from "react-router-dom";
import { getAvailableDays, getDots, getSensors } from "../../api";
import Panel from "../../components/Panel";
import { FiltersContext } from "../../store/filtersContext";

const Dashboard = () => {
  const navigate = useNavigate();

  const { reactor, mode, day, month, year } = useContext(FiltersContext);

  const [chartsData, setChartsData] = useState([]);

  const getDataChartWithDayMode = async ({ reactor, day }) => {
    const dots = await getDots({
      reactorRefName: reactor,
      date: day,
    });

    const dotsData = dots.data;

    if (!dotsData) return [];
    if (dotsData.length === 0) return [];

    const labels = Object.keys(dotsData);
    const values = Object.entries(dotsData);

    if (!labels || labels.length === 0) return [];
    if (!values || values.length === 0) return [];

    const sensorsRes = await getSensors();
    const sensorsLabels = Object.keys(sensorsRes.data);

    return sensorsLabels.map((sLabel) => {
      return {
        label: sLabel,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
          },
          interaction: {
            intersect: false,
            mode: "index",
          },
          scales: {
            y: {
              grid: {
                drawBorder: false,
                display: true,
                drawOnChartArea: true,
                drawTicks: false,
                borderDash: [5, 5],
                color: "rgba(255, 255, 255, .2)",
              },
              ticks: {
                display: true,
                color: "#f8f9fa",
                padding: 10,
                font: {
                  size: 14,
                  weight: 300,
                  family: "Roboto",
                  style: "normal",
                  lineHeight: 2,
                },
              },
            },
            x: {
              grid: {
                drawBorder: false,
                display: false,
                drawOnChartArea: false,
                drawTicks: false,
                borderDash: [5, 5],
              },
              ticks: {
                display: true,
                color: "#f8f9fa",
                padding: 10,
                font: {
                  size: 14,
                  weight: 300,
                  family: "Roboto",
                  style: "normal",
                  lineHeight: 2,
                },
              },
            },
          },
        },
        data: {
          labels,
          datasets: [
            {
              label: "",
              tension: 0,
              borderWidth: 4,
              pointRadius: 5,
              pointBackgroundColor: "rgba(255, 255, 255, .8)",
              pointBorderColor: "transparent",
              borderColor: "rgba(255, 255, 255, .8)",
              backgroundColor: "transparent",
              fill: true,
              data: values?.map((v) => v[1])?.map((d) => d[sLabel]) || [],
              maxBarThickness: 6,
            },
          ],
        },
      };
    });
  };

  const getDataChartWithMonthMode = async ({ reactor, month }) => {
    const availableDays = (await getAvailableDays(reactor)).data;
    const dates = availableDays.filter((day) => day.includes(month));

    let dots = {};
    for (let i = 0; i < dates.length; i++) {
      const _dots = (
        await getDots({
          reactorRefName: reactor,
          date: day,
        })
      ).data;

      function calculateAverages(data) {
        const sums = {};
        const counts = {};

        // Iterate over each timestamp entry in the data
        for (let timestamp in data) {
          const values = data[timestamp];

          // Sum the values for each parameter
          for (let key in values) {
            if (!sums[key]) {
              sums[key] = 0;
              counts[key] = 0;
            }
            sums[key] += values[key];
            counts[key] += 1;
          }
        }

        // Calculate the averages
        const averages = {};
        for (let key in sums) {
          averages[key] = sums[key] / counts[key];
        }

        return averages;
      }

      dots = { ...dots, [day]: calculateAverages(_dots) };
    }

    const dotsData = dots;

    if (!dotsData) return [];
    if (dotsData.length === 0) return [];

    const labels = Object.keys(dotsData);
    const values = Object.entries(dotsData);

    if (!labels || labels.length === 0) return [];
    if (!values || values.length === 0) return [];

    const sensorsRes = await getSensors();
    const sensorsLabels = Object.keys(sensorsRes.data);

    return sensorsLabels.map((sLabel) => {
      return {
        label: sLabel,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
          },
          interaction: {
            intersect: false,
            mode: "index",
          },
          scales: {
            y: {
              grid: {
                drawBorder: false,
                display: true,
                drawOnChartArea: true,
                drawTicks: false,
                borderDash: [5, 5],
                color: "rgba(255, 255, 255, .2)",
              },
              ticks: {
                display: true,
                color: "#f8f9fa",
                padding: 10,
                font: {
                  size: 14,
                  weight: 300,
                  family: "Roboto",
                  style: "normal",
                  lineHeight: 2,
                },
              },
            },
            x: {
              grid: {
                drawBorder: false,
                display: false,
                drawOnChartArea: false,
                drawTicks: false,
                borderDash: [5, 5],
              },
              ticks: {
                display: true,
                color: "#f8f9fa",
                padding: 10,
                font: {
                  size: 14,
                  weight: 300,
                  family: "Roboto",
                  style: "normal",
                  lineHeight: 2,
                },
              },
            },
          },
        },
        data: {
          labels,
          datasets: [
            {
              label: "",
              tension: 0,
              borderWidth: 4,
              pointRadius: 5,
              pointBackgroundColor: "rgba(255, 255, 255, .8)",
              pointBorderColor: "transparent",
              borderColor: "rgba(255, 255, 255, .8)",
              backgroundColor: "transparent",
              fill: true,
              data: values?.map((v) => v[1])?.map((d) => d[sLabel]) || [],
              maxBarThickness: 6,
            },
          ],
        },
      };
    });
  };

  const getDataChartWithYearMode = async ({ reactor, year }) => {
    const availableDays = (await getAvailableDays(reactor)).data;

    const dates = availableDays.filter((day) => day.includes(year));

    let dots = {};
    for (let i = 0; i < dates.length; i++) {
      const _dots = (
        await getDots({
          reactorRefName: reactor,
          date: day,
        })
      ).data;

      function calculateAverages(data) {
        const sums = {};
        const counts = {};

        // Iterate over each timestamp entry in the data
        for (let timestamp in data) {
          const values = data[timestamp];

          // Sum the values for each parameter
          for (let key in values) {
            if (!sums[key]) {
              sums[key] = 0;
              counts[key] = 0;
            }
            sums[key] += values[key];
            counts[key] += 1;
          }
        }

        // Calculate the averages
        const averages = {};
        for (let key in sums) {
          averages[key] = sums[key] / counts[key];
        }

        return averages;
      }

      dots = { ...dots, [day]: calculateAverages(_dots) };
    }

    const dotsData = dots;

    if (!dotsData) return [];
    if (dotsData.length === 0) return [];

    const labels = Object.keys(dotsData);
    const values = Object.entries(dotsData);

    if (!labels || labels.length === 0) return [];
    if (!values || values.length === 0) return [];

    const sensorsRes = await getSensors();
    const sensorsLabels = Object.keys(sensorsRes.data);

    return sensorsLabels.map((sLabel) => {
      return {
        label: sLabel,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
          },
          interaction: {
            intersect: false,
            mode: "index",
          },
          scales: {
            y: {
              grid: {
                drawBorder: false,
                display: true,
                drawOnChartArea: true,
                drawTicks: false,
                borderDash: [5, 5],
                color: "rgba(255, 255, 255, .2)",
              },
              ticks: {
                display: true,
                color: "#f8f9fa",
                padding: 10,
                font: {
                  size: 14,
                  weight: 300,
                  family: "Roboto",
                  style: "normal",
                  lineHeight: 2,
                },
              },
            },
            x: {
              grid: {
                drawBorder: false,
                display: false,
                drawOnChartArea: false,
                drawTicks: false,
                borderDash: [5, 5],
              },
              ticks: {
                display: true,
                color: "#f8f9fa",
                padding: 10,
                font: {
                  size: 14,
                  weight: 300,
                  family: "Roboto",
                  style: "normal",
                  lineHeight: 2,
                },
              },
            },
          },
        },
        data: {
          labels,
          datasets: [
            {
              label: "",
              tension: 0,
              borderWidth: 4,
              pointRadius: 5,
              pointBackgroundColor: "rgba(255, 255, 255, .8)",
              pointBorderColor: "transparent",
              borderColor: "rgba(255, 255, 255, .8)",
              backgroundColor: "transparent",
              fill: true,
              data: values?.map((v) => v[1])?.map((d) => d[sLabel]) || [],
              maxBarThickness: 6,
            },
          ],
        },
      };
    });
  };

  useLayoutEffect(() => {
    if (localStorage.getItem("token").length === 0)
      return navigate({ pathname: "/" }, { replace: true });
  }, [navigate]);

  useEffect(() => {
    (async () => {
      switch (mode) {
        case "day":
          setChartsData(await getDataChartWithDayMode({ reactor, day }));
          break;
        case "month":
          setChartsData(await getDataChartWithMonthMode({ reactor, month }));
          break;
        case "year":
          setChartsData(await getDataChartWithYearMode({ reactor, year }));
          break;
        default:
          break;
      }
    })();
  }, [reactor, mode, day, month, year]);

  return (
    <>
      <Navbar currPathname="dashboard" />
      <div className="container" style={{ paddingTop: 80 }}>
        <section style={{ minHeight: "100vh" }}>
          <h2 className="no-user-manipulate mt-3 mb-2">Dashboard</h2>
          <div className="d-flex flex-wrap gap-3">
            <div className="col-lg-3 col-md-12 col-12 m-0 mt-2 mb-2 me-5">
              <div className="card-body p-0 d-flex justify-content-center">
                <Panel />
              </div>
            </div>
            <div className="row col-lg-8 col-md-12 mt-4">
              {chartsData?.map((chat, i) => (
                <div
                  key={"chart-" + i}
                  className="col-lg-6 col-md-12 col-12 m-0 mt-3 mb-4"
                >
                  <Chart
                    label={chat?.label || ""}
                    data={chat?.data || []}
                    options={chat?.options || []}
                  />
                </div>
              )) || null}
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Dashboard;
