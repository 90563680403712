import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import Navbar from "../../components/Navbar";
import Toast from "../../components/Toast";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
library.add(fas);

const Profile = () => {
  const navigate = useNavigate();

  const [user, setUser] = useState({
    name: "",
    email: "",
    profile: "",
    description: "",
    company: "",
    updated: "",
  });

  const [notification, setNotification] = useState({
    sts: false,
    title: "",
    msg: "",
    type: "default",
  });

  const [passwordMode, setPasswordMode] = useState(false);
  const [crrPassword, setCrrPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmationPassword, setConfirmationPassword] = useState("");

  useEffect(() => {
    const _user = JSON.parse(localStorage.getItem("userInfo"));
    if (_user) setUser(_user);
    console.log(_user);
  }, []);

  // useLayoutEffect(() => {
  //   window.addEventListener("popstate", function (event) {
  //     navigate({ pathname: "/" }, { replace: true });
  //   });
  // }, []);

  useLayoutEffect(() => {
    if (localStorage.getItem("token").length === 0)
      return navigate({ pathname: "/" }, { replace: true });
  }, []);

  const handleGetOut = () => {
    localStorage.setItem("token", "");
    localStorage.setItem("userInfo", "{}");
    navigate({ pathname: "/" }, { replace: true });
  };

  const handleChangePassword = (e) => {
    if (crrPassword.length === 0)
      return setNotification({
        sts: true,
        title: "Erro",
        type: "danger",
        msg: "Senha atual não informada!",
      });
    if (crrPassword.length < 6)
      return setNotification({
        sts: true,
        title: "Erro",
        type: "danger",
        msg: "Senha atual inválida!",
      });

    if (newPassword.length === 0)
      return setNotification({
        sts: true,
        title: "Erro",
        type: "danger",
        msg: "Nova senha não informada!",
      });
    if (newPassword.length < 6)
      return setNotification({
        sts: true,
        title: "Erro",
        type: "danger",
        msg: "A nova senha é muito curta!",
      });

    if (newPassword === crrPassword)
      return setNotification({
        sts: true,
        title: "Erro",
        type: "danger",
        msg: "A nova senha deve ser diferente da atual!",
      });

    if (confirmationPassword.length === 0)
      setNotification({
        sts: true,
        title: "Erro",
        type: "danger",
        msg: "Senha não confirmada!",
      });
    if (newPassword !== confirmationPassword)
      return setNotification({
        sts: true,
        title: "Erro",
        type: "danger",
        msg: "A nova senha não foi confirmada!",
      });

    return setNotification({
      sts: true,
      title: "Aviso",
      type: "success",
      msg: "Senha alterada com sucesso!",
    });
  };

  const userDetails = useMemo(
    () => (
      <form className="card-body bg-white text-dark">
        {user.profile === "ADM" && (
          <div className="d-flex bg-light align-items-center mb-1 p-2 rounded">
            <FontAwesomeIcon
              icon="fa-user"
              className="ms-1 me-3"
              style={{ paddingRight: 3 }}
            />
            <p className="card-text fs-6 m-0 mb-0 col">{user.profile}</p>
          </div>
        )}
        <div className="d-flex bg-light align-items-center mb-1 p-2 rounded">
          <FontAwesomeIcon icon="fa-at" className="ms-1 me-3" />
          <p className="card-text fs-6 m-0 mb-0 col">{user.email}</p>
        </div>
        <div className="d-flex bg-light align-items-center mb-1 p-2 rounded">
          <FontAwesomeIcon icon="fa-globe" className="ms-1 me-3" />
          <p className="card-text fs-6 m-0 mb-0 col">{user.company}</p>
        </div>
        <div className="d-flex bg-light align-items-center mb-1 p-2 rounded">
          <FontAwesomeIcon
            icon="fa-info"
            className="ms-2 me-3"
            style={{ paddingRight: 6 }}
          />
          <p className="card-text fs-6 m-0 mb-0 col">{user.description}</p>
        </div>
        <div className="d-flex btn btn-outline-danger align-items-center mt-3 mb-0 p-2 rounded">
          <FontAwesomeIcon icon="fa-key" className="ms-1 me-3" />
          <button
            className="btn btn-danger fs-6 m-0 mb-0 col"
            onClick={(e) => {
              setPasswordMode(true);
            }}
          >
            Mudar senha
          </button>
        </div>
        <div
          className="d-flex align-items-center mt-3 mb-1 p-2 rounded btn btn-danger text-light"
          style={{ marginLeft: 240, border: "1px solid #ff0000" }}
          onClick={handleGetOut}
        >
          <FontAwesomeIcon icon="fa-right-from-bracket" className="ms-1 me-3" />
          <p className="card-text fs-6 m-0 mb-0 col">Sair</p>
        </div>
      </form>
    ),
    [user]
  );

  const changePasswordContent = (
    <form className="card-body bg-white text-dark">
      <div className="form-floating">
        <input
          type="password"
          className="form-control"
          id="floatingPassword"
          placeholder="Password"
          autoComplete="off"
          style={{ paddingLeft: 24 }}
          onChange={(e) => {
            setCrrPassword(e.target.value);
          }}
        />
        <label style={{ paddingLeft: 12 }}>Senha atual</label>
      </div>
      <div
        className="d-flex justify-content-center"
        style={{ marginTop: -10, marginBottom: 10 }}
      >
        <div
          className="w-90"
          style={{ minHeight: 1, background: "#3333" }}
        ></div>
      </div>
      <div className="form-floating mt-1">
        <input
          type="password"
          className="form-control"
          id="floatingPassword"
          placeholder="Password"
          autoComplete="off"
          style={{ paddingLeft: 24 }}
          onChange={(e) => {
            setNewPassword(e.target.value);
          }}
        />
        <label style={{ paddingLeft: 12 }}>Nova senha</label>
      </div>
      <div
        className="d-flex justify-content-center"
        style={{ marginTop: -10, marginBottom: 10 }}
      >
        <div
          className="w-90"
          style={{ minHeight: 1, background: "#3333" }}
        ></div>
      </div>
      <div className="form-floating mt-1">
        <input
          type="password"
          className="form-control"
          id="floatingPassword"
          placeholder="Password"
          autoComplete="off"
          style={{ paddingLeft: 24 }}
          onChange={(e) => {
            setConfirmationPassword(e.target.value);
          }}
        />
        <label style={{ paddingLeft: 12 }}>Nova senha (Confirmação)</label>
      </div>
      <div
        className="d-flex justify-content-center"
        style={{ marginTop: -10, marginBottom: 20 }}
      >
        <div
          className="w-90"
          style={{ minHeight: 1, background: "#3333" }}
        ></div>
      </div>

      <div className="d-flex justify-content-center gap-3 p-2 mt-1">
        <button
          className="btn btn-secondary fs-6 m-0 mb-0 col"
          onClick={(e) => {
            setPasswordMode(false);
          }}
        >
          Cancelar
        </button>
        <button
          className="btn btn-danger fs-6 m-0 mb-0 col"
          type="button"
          onClick={handleChangePassword}
        >
          Alterar
        </button>
      </div>
    </form>
  );

  return (
    <>
      <Toast
        title={notification.title}
        delay={5000}
        setShow={(sts) => setNotification({ ...notification, sts })}
        msg={notification.msg}
        type={notification.type}
        show={notification.sts}
      />
      <Navbar currPathname="profile" />
      <div className="container" style={{ paddingTop: 80 }}>
        <div className="d-flex justify-content-center mt-4 mb-4">
          <div className="d-flex text-start">
            <div
              className="card shadow-lg bg-dark text-tertiary"
              style={{ color: "#3332" }}
            >
              <div className="card-body p-3 px-4">
                <h5 className="card-title fs-4">{user.name}</h5>
                <p
                  className="card-text"
                  style={{ marginTop: -12, fontSize: 12 }}
                >
                  Última atualização: {user.updated}
                </p>
              </div>
              {!passwordMode ? userDetails : changePasswordContent}
              <div className="card-footer text-end p-1"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
