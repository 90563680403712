// const HOST = "http://192.168.31.10:4000"; //window.location.origin;
// const HOST = "http://localhost:4000"; //window.location.origin;
const HOST = window.location.origin; //window.location.origin;

export async function logIn(data = {}) {
  const response = await fetch(`${HOST}/webApi/auth`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    referrerPolicy: "same-origin",
    body: JSON.stringify(data),
  });
  return response.json();
}

export async function getReactors() {
  const token = localStorage.getItem("token");
  if (!token) return { error: 401 };

  const response = await fetch(`${HOST}/api/reactors`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: token,
    },
    referrerPolicy: "same-origin",
  });
  return response.json();
}

export async function getSensors() {
  const token = localStorage.getItem("token");
  if (!token) return { error: 401 };

  const response = await fetch(`${HOST}/api/sensors/${JSON.stringify({})}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: token,
    },
    referrerPolicy: "same-origin",
  });
  return response.json();
}

export async function getAvailableDays(reactorRefName) {
  if (reactorRefName === "") return { error: 400 };
  const token = localStorage.getItem("token");
  if (!token) return { error: 401 };

  const response = await fetch(`${HOST}/api/availableDays/${reactorRefName}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: token,
    },
    referrerPolicy: "same-origin",
  });
  return response.json();
}

export async function getDots(filters = {}) {
  const token = localStorage.getItem("token");
  if (!token) return { error: 401 };

  const response = await fetch(`${HOST}/api/dots/${JSON.stringify(filters)}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: token,
    },
    referrerPolicy: "same-origin",
  });
  return response.json();
}
