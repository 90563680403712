import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./global.css";
import "./replacement.css";
import "./scroll.css";

import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { FiltersProvider } from "./store/filtersContext";
import Home from "./pages/Home";
import Dashboard from "./pages/Dashboard";
import Profile from "./pages/Profile";

function App() {
  return (
    <div style={{ minHeight: "100vh" }}>
      <Router>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <FiltersProvider>
            <Routes>
              <Route Component={Home} path="/" />
              <Route Component={Profile} path="/profile" />
              <Route Component={Dashboard} path="/dashboard" />
            </Routes>
          </FiltersProvider>
        </LocalizationProvider>
      </Router>
    </div>
  );
}

export default App;
