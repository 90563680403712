import React, { useContext, useEffect, useState } from "react";
import { FiltersContext } from "../store/filtersContext";
import {
  DateCalendar,
  DayCalendarSkeleton,
  MonthCalendar,
  PickersDay,
} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { Badge } from "@mui/material";

import { YearCalendar } from "@mui/x-date-pickers";
import { getAvailableDays, getReactors } from "../api";

function Panel() {
  const {
    reactor,
    mode,
    day,
    month,
    year,
    setReactor,
    setMode,
    setDay,
    setMonth,
    setYear,
  } = useContext(FiltersContext);

  const [loading, setLoading] = useState(false);
  const [reactorsKeys, setReactorsKeys] = useState([]);
  const [availableDays, setAvailableDays] = useState([]);

  useEffect(() => {}, [mode, day, month]);

  useEffect(() => {
    (async () => {
      const reactors = await getReactors();
      const reactorsKeys = Object.keys(reactors.data);
      setReactorsKeys(reactorsKeys);
    })();
  }, []);

  useEffect(() => {
    if (!reactor) return;
    setTimeout(async () => {
      const availableDays = await getAvailableDays(reactor);
      console.log(availableDays.data);
      setAvailableDays(availableDays.data);
    }, 10);
  }, [reactor]);

  const highlightedDays = availableDays ? availableDays : [];

  function ServerDay(props) {
    const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

    const isSelected =
      !props.outsideCurrentMonth &&
      highlightedDays.reduce(
        (acc, crr) => acc || dayjs(day).isSame(crr, "days"),
        false
      );

    return (
      <Badge
        key={props.day.toString()}
        overlap="circular"
        badgeContent={isSelected ? "🟢" : undefined}
      >
        <PickersDay
          {...other}
          outsideCurrentMonth={outsideCurrentMonth}
          day={day}
        />
      </Badge>
    );
  }

  return (
    <div className="d-flex justify-content-center" style={{ maxWidth: "100%" }}>
      <div className="col-md-12 col-12 m-2 row">
        <div
          className="card bg-gradient-success shadow-success p-0"
          style={{ minWidth: 320, maxWidth: 320 }}
        >
          <div className="card mb-2 mx-0">
            <div className="card-header bg-gradient-success shadow-success ">
              <div className="d-flex gap-3 mx-0 mb-0">
                <div className="card col">
                  <div className="card-body py-1 px-2">
                    <div key="fs-1" className="form-floating">
                      <select
                        className="form-select"
                        id="reactorSelect"
                        aria-label="Definir do reator"
                        style={{ border: "none", paddingLeft: 8 }}
                        onChange={(e) => {
                          setReactor(e.currentTarget.value);
                        }}
                        value={reactor}
                      >
                        {reactorsKeys.map((rk, i) => (
                          <option key={`rk-${i}`} value={rk}>
                            {rk}
                          </option>
                        ))}
                      </select>
                      <label htmlFor="reactorSelect">Reator</label>
                    </div>
                  </div>
                </div>
                <div className="card col">
                  <div className="card-body py-1 px-2">
                    <div key="fs-2" className="form-floating">
                      <select
                        className="form-select"
                        id="modeSelect"
                        aria-label="Definir do período"
                        style={{ border: "none", paddingLeft: 8 }}
                        onChange={(e) => setMode(e.target.value)}
                        value={mode}
                      >
                        <option value="day">Diário</option>
                        <option value="month">Mensal</option>
                        <option value="year">Anual</option>
                      </select>
                      <label htmlFor="reactorSelect">Período</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body p-0 pt-1 pb-1">
              <div className="hidden-scrollbar">
                {reactor && mode === "day" && (
                  <DateCalendar
                    value={dayjs(day)}
                    loading={loading}
                    renderLoading={() => <DayCalendarSkeleton />}
                    slots={{
                      day: ServerDay,
                    }}
                    slotProps={{
                      day: {
                        highlightedDays,
                      },
                    }}
                    disableFuture={false} // change this
                    displayWeekNumber={false}
                    minDate={dayjs("2023-01-01")}
                    shouldDisableYear={(year) =>
                      ["2023", "2024", "2025"].includes(year)
                    }
                    onYearChange={(year) => {
                      setLoading(true);
                      setTimeout(() => {
                        setLoading(false);
                      }, 1000);
                      // set Loading calendar

                      // get from api lis of dates to this year

                      // set loading off
                    }}
                    onChange={(value) => {
                      console.log("value:",value);
                      setDay(dayjs(value).format("YYYY-MM-DD"));
                      console.log(dayjs(value).format("YYYY-MM-DD"));
                      // console.log(dayjs(value));
                    }}
                  />
                )}
                {reactor && mode === "month" && (
                  <>
                    <div className="d-flex justify-content-center col w-100 hidden-scrollbar">
                      <MonthCalendar
                        className="hidden-scrollbar"
                        style={{ height: 280, overflowY: "scroll" }}
                        value={
                          month.length === 2
                            ? dayjs(month, "MM")
                            : month.length === 7
                            ? dayjs(month, "YYYY-MM")
                            : dayjs("2023-01-01")
                        }
                        onChange={(value) => {
                          const len = String(month).length;

                          if (len === 0 || len === 2) {
                            setMonth(dayjs(value).format("MM"));
                            return;
                          }
                          if (len === 4) {
                            setMonth(
                              String(month) + "-" + dayjs(value).format("MM")
                            );
                            return;
                          }
                          if (len === 7) {
                            setMonth(
                              dayjs(month).format("YYYY") +
                                "-" +
                                dayjs(value).format("MM")
                            );
                            return;
                          }
                        }}
                      />
                      <YearCalendar
                        className="hidden-scrollbar"
                        minDate={dayjs("2022-01-01")}
                        maxDate={dayjs("2031-01-01")}
                        value={
                          month.length === 4
                            ? dayjs(month, "YYYY")
                            : month.length === 7
                            ? dayjs(month, "YYYY-MM")
                            : dayjs("2023-01-01")
                        }
                        onChange={(value) => {
                          const len = String(month).length;

                          if (len === 0 || len === 4) {
                            setMonth(dayjs(value).format("YYYY"));
                            return;
                          }

                          if (len === 2) {
                            setMonth(
                              dayjs(value).format("YYYY") + "-" + String(month)
                            );
                            return;
                          }

                          if (len === 7) {
                            setMonth(
                              dayjs(value).format("YYYY") +
                                "-" +
                                dayjs(month).format("MM")
                            );
                            return;
                          }
                        }}
                      />
                    </div>
                  </>
                )}
                {reactor && mode === "year" && (
                  <div className="d-flex justify-content-center">
                    <YearCalendar
                      minDate={dayjs("2022-01-01")}
                      maxDate={dayjs("2031-01-01")}
                      value={
                        String(year).length === 4
                          ? dayjs(year, "YYYY")
                          : dayjs("2024", "YYYY")
                      }
                      onChange={(value) => {
                        setYear(dayjs(value).format("YYYY"));
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Panel;
