import React from "react";

import { Toast, ToastContainer } from "react-bootstrap";

function ToastNotification({ show, setShow, title, msg, delay, type }) {
  return (
    <ToastContainer position="top-end" className="p-3 mt-5">
      <Toast
        onClose={() => setShow(false)}
        show={show}
        delay={delay}
        className=" bg-light"
        autohide
      >
        <Toast.Header
          style={
            type === "success"
              ? {
                  backgroundColor: "#81cd2c",
                  color: "#28611e",
                  borderTopLeftRadius: 10,
                  borderTopRightRadius: 10,
                }
              : type === "danger"
              ? {
                  backgroundColor: "#f43",
                  color: "#892a38",
                  borderTopLeftRadius: 10,
                  borderTopRightRadius: 10,
                }
              : { borderTopLeftRadius: 10, borderTopRightRadius: 10 }
          }
        >
          <strong className="me-auto">{title ? title : "Notificação"}</strong>
          <small></small>
        </Toast.Header>
        <Toast.Body>{msg}</Toast.Body>
      </Toast>
    </ToastContainer>
  );
}

export default ToastNotification;
