import React, { useEffect, useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { logIn } from "../../api";
import ToastNotification from "../../components/Toast";

const Home = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState(localStorage.getItem("email") || "");
  const [password, setPassword] = useState(
    localStorage.getItem("password") || ""
  );
  const [remember, setRemember] = useState(
    String(localStorage.getItem("email")).length > 0
  );

  const [notification, setNotification] = useState({
    sts: false,
    title: "",
    msg: "",
    type: "default",
  });

  const handleRedirect = (event) => {
    event.preventDefault();

    if (email.length < 6 || !email.includes("@")) {
      setNotification({
        sts: true,
        title: "Erro",
        type: "danger",
        msg: "E-mail inválido!",
      });
      return;
    }
    if (password.length < 4) {
      setNotification({
        sts: true,
        title: "Erro",
        type: "danger",
        msg: "Senha inválida!",
      });
      return;
    }

    logIn({
      email,
      password,
    })
      .then((data) => {
        console.log(data);
        if (data?.done) {
          localStorage.setItem("token", data.token);
          localStorage.setItem("userInfo", JSON.stringify(data.userInfo));
          navigate({ pathname: "./profile" });
          return;
        }
        setNotification({
          sts: true,
          title: "Erro",
          type: "danger",
          msg: "Credenciais inválidas!",
        });
      })
      .catch((err) => {
        setNotification({
          sts: true,
          title: "Erro",
          type: "danger",
          msg: "Error na conexão!",
        });
        console.log(err);
      });
  };

  useEffect(() => {
    // precivalvictor@gmail.com
    if (remember) {
      localStorage.setItem("email", email);
      localStorage.setItem("password", password);
    } else {
      localStorage.setItem("email", "");
      localStorage.setItem("password", "");
    }
  }, [remember, email, password]);

  return (
    <>
      <div
        style={{
          backgroundImage: "url('./bg-login.webp')",
          height: "100vh",
          backgroundSize: "cover",
          backgroundPosition: "center",
          minWidth: 320,
        }}
      >
        <ToastNotification
          title={notification.title}
          delay={5000}
          setShow={(sts) => setNotification({ ...notification, sts })}
          msg={notification.msg}
          type={notification.type}
          show={notification.sts}
        />
        <section className="p-3 p-md-4 p-xl-5" style={{ maxHeight: "100vh" }}>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-md-9 col-lg-7 col-xl-6 col-xxl-5">
                <div
                  className="card border border-light-subtle rounded-4 mt-4 shadow-lg"
                  style={{ backgroundColor: "#ffffffff" }}
                >
                  <div className="card-body p-0 p-md-0 p-xl-0">
                    <div className="row">
                      <div className="col-12">
                        <div className="mb-2">
                          <div className="text-center mb-4">
                            <img
                              src="./logo.png"
                              alt="BootstrapBrain Logo"
                              width="100%"
                              height="auto"
                              style={{
                                borderTopLeftRadius: 10,
                                borderTopRightRadius: 10,
                              }}
                            />
                          </div>
                          <h4 className="text-center">
                            Acesso ao UASB Monitor
                          </h4>
                        </div>
                      </div>
                    </div>

                    <div className="d-flex row py-3 pb-5 px-5">
                      <form>
                        <div className="row gy-3 overflow-hidden px-2">
                          <div className="col-12">
                            <div className="form-floating mb-3">
                              <input
                                type="email"
                                className="form-control"
                                name="email"
                                id="email"
                                placeholder="name@example.com"
                                style={{ backgroundColor: "#fff0 !important" }}
                                autoComplete="off"
                                value={email}
                                onChange={(e) => {
                                  setEmail(e.target.value);
                                }}
                                required
                              />
                              <label
                                htmlFor="email"
                                className="form-label"
                                style={{ marginLeft: 0 }}
                              >
                                E-mail
                              </label>
                            </div>
                            <div
                              className="d-flex justify-content-center"
                              style={{ marginTop: -18, marginBottom: 0 }}
                            >
                              <div
                                className="w-100"
                                style={{ minHeight: 1, background: "#3333" }}
                              ></div>
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="form-floating mb-3">
                              <input
                                type="password"
                                className="form-control"
                                name="password"
                                id="password"
                                placeholder="Senha"
                                autoComplete="off"
                                value={password}
                                onChange={(e) => {
                                  setPassword(e.target.value);
                                }}
                                required
                              />
                              <label
                                htmlFor="password"
                                className="form-label"
                                style={{ marginLeft: 0 }}
                              >
                                Senha
                              </label>
                            </div>
                            <div
                              className="d-flex justify-content-center"
                              style={{ marginTop: -18, marginBottom: 0 }}
                            >
                              <div
                                className="w-100"
                                style={{ minHeight: 1, background: "#3333" }}
                              ></div>
                            </div>
                          </div>

                          <div className="col-12 d-flex justify-content-end m-0 mt-4 mb-3">
                            <div className="form-check">
                              <input
                                className="form-check-input is-valid"
                                type="checkbox"
                                name="remember_me"
                                id="remember_me"
                                checked={remember}
                                onChange={(e) => {
                                  setRemember(e.target.checked);
                                }}
                              />
                              <label
                                className="form-check-label text-secondary"
                                htmlFor="remember_me"
                              >
                                Lembrar credenciais
                              </label>
                            </div>
                          </div>

                          <div className="col-12 mt-0">
                            <div className="d-grid">
                              <button
                                className="btn bsb-btn-xl btn-primary"
                                type="buttom"
                                onClick={handleRedirect}
                              >
                                Entrar
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Home;
