export default function formatNumber(num) {
  if (isNaN(num)) return "-";

  const units = [
    { value: 1e-24, symbol: "y" },
    { value: 1e-21, symbol: "z" },
    { value: 1e-18, symbol: "a" },
    { value: 1e-15, symbol: "f" },
    { value: 1e-12, symbol: "p" },
    { value: 1e-9, symbol: "n" },
    { value: 1e-6, symbol: "µ" },
    { value: 1e-3, symbol: "m" },
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
    { value: 1e21, symbol: "Z" },
    { value: 1e24, symbol: "Y" },
  ];

  const unit = units
    .slice()
    .reverse()
    .find((unit) => num >= unit.value);

  if (unit?.value)
    return (num / unit.value).toFixed(2).replace(".", ",") + unit.symbol;
  return num.toFixed(2).replace(".", ",");
}
