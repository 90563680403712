import React, { createContext, useState } from "react";

export const FiltersContext = createContext();

const initialState = { reactor: "", mode: "day", day: "2024-01-01", month: "", year: "" };
const recoveredState = JSON.parse(
  localStorage.getItem("filters") || JSON.stringify(initialState)
);

export const FiltersProvider = ({ children }) => {
  const [filters, setFilters] = useState(recoveredState);

  const setFiltersSafe = (filter) => {
    const newContent = { ...filters, ...filter };
    localStorage.setItem("filters", JSON.stringify(newContent));
    setFilters(newContent);
  };

  const setReactor = (reactor) => setFiltersSafe({ reactor });
  const setMode = (mode) => setFiltersSafe({ mode });
  const setDay = (day) => setFiltersSafe({ day });
  const setMonth = (month) => setFiltersSafe({ month });
  const setYear = (year) => setFiltersSafe({ year });

  return (
    <FiltersContext.Provider
      value={{ ...filters, setReactor, setMode, setDay, setMonth, setYear }}
    >
      {children}
    </FiltersContext.Provider>
  );
};
